











import Vue from 'vue'
//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
//引入相关样式
import '@vue-office/docx/lib/index.css'
import VueOfficeExcel from '@vue-office/excel'
//引入相关样式
import '@vue-office/excel/lib/index.css'
import VueOfficePdf from '@vue-office/pdf'
import moment from "moment/moment";
import pdf from 'vue-pdf'
export default Vue.extend({
  components: {
    //@ts-ignore
    VueOfficePdf,
    //@ts-ignore
    VueOfficeExcel,
    //@ts-ignore
    VueOfficeDocx
  },
  // components: {
  //   VueOfficePdf,
  //   VueOfficeDocx,
  //   VueOfficeExcel
  // },
  data() {
    return {
      url: '',
      key: '',
      config: {},
      fileType: '',
    }
  },
  mounted() {
    this.key = moment(new Date()).format('YYYYMMDDHHmmss')
    const query: any = this.$route.query
    
    this.fileType = query.type
    this.url = query.url
  },
  methods: {
    goback() {
      this.$router.go(-1)
    },
    onDocumentReady() {
      console.log("Document is loaded");
    },
    rendered() {
      console.log("渲染完成")
    }
  }
});

